import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import { TeamState } from "@store/teams/teams-slice-types";
import { teamAdapter } from "@store/teams/teams-slice";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { companyTeamsAsMemberSelector } from "@store/members/members-selector";
import { SdbTeam } from "@custom-types/teams-types";
import { MIN_CHARACTERS_FOR_SEARCH } from "@utils/project-utils";

/** Return the list of teams. */
export const teamsSelector: (state: RootState) => SdbTeam[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const teams = teamAdapter.getSelectors().selectAll(state.teams);
    const companyTeamsAsMember = companyTeamsAsMemberSelector(state);
    const searchText = state.ui.search.debouncedSearchText.toLowerCase();
    const sdbTeams: SdbTeam[] = teams
      .filter((team) => team.name.toLowerCase().includes(searchText))
      .map((team) => {
        const teamAsMember = companyTeamsAsMember.find(
          (teamAsMember) => teamAsMember.identity === team.id
        );
        return {
          ...team,
          role: teamAsMember?.role ?? CoreAPITypes.EUserCompanyRole.member,
        };
      });

    return sdbTeams.sort(
      (teamA, teamB) =>
        new Date(teamB.modifiedAt).getTime() -
        new Date(teamA.modifiedAt).getTime()
    );
  }
);

/**
 * Gets a team entity by providing the team ID
 */
export function getTeamByIdSelector(
  teamId: SphereDashboardAPITypes.TeamId | undefined
): (state: RootState) => SphereDashboardAPITypes.ITeam | undefined {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!teamId) {
        return undefined;
      }
      return teamAdapter.getSelectors().selectById(state.teams, teamId);
    }
  );
}

/**
 * Get the selected team using the selected team ID and finding it on the store,
 * returns null if there's no teamId or if the team could not be found in the store.
 */
export const selectedTeamIdSelector: (
  state: RootState
) => SphereDashboardAPITypes.ITeam | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const selectedTeamId = state.teams.selectedTeam.id;
    if (!selectedTeamId) {
      return null;
    }

    return (
      teamAdapter.getSelectors().selectById(state.teams, selectedTeamId) ?? null
    );
  }
);

/**
 * Get the selected team using the selected team ID and finding it on the store,
 * returns null if there's no teamId or if the team could not be found in the store,
 * including the user's role in the team if available.
 */
export const selectedTeamWithRoleSelector: (
  state: RootState
) => SdbTeam | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const selectedTeamId = state.teams.selectedTeam.id;
    if (!selectedTeamId) {
      return null;
    }

    const team =
      teamAdapter.getSelectors().selectById(state.teams, selectedTeamId) ??
      null;

    if (!team) {
      return null;
    }

    const companyTeamsAsMember = companyTeamsAsMemberSelector(state);
    const teamAsMember = companyTeamsAsMember.find(
      (teamAsMember) => teamAsMember.identity === team.id
    );

    return {
      ...team,
      role: teamAsMember?.role ?? CoreAPITypes.EUserCompanyRole.member,
    };
  }
);

/**
 * Get the team using the team ID and finding it on the store,
 * returns null if there's no teamId or if the team could not be found in the store,
 * including the user's role in the team if available.
 */
export function teamWithRoleByIdSelector(
  teamId: SphereDashboardAPITypes.TeamId | undefined
): (state: RootState) => SdbTeam | null {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!teamId) {
        return null;
      }

      const team =
        teamAdapter.getSelectors().selectById(state.teams, teamId) ?? null;

      if (!team) {
        return null;
      }

      const companyTeamsAsMember = companyTeamsAsMemberSelector(state);
      const teamAsMember = companyTeamsAsMember.find(
        (teamAsMember) => teamAsMember.identity === team.id
      );

      return {
        ...team,
        role: teamAsMember?.role ?? CoreAPITypes.EUserCompanyRole.member,
      };
    }
  );
}

/**
 * Returns the fetching properties of the teams slice.
 */
export const fetchingTeamsFlagsSelector: (
  state: RootState
) => TeamState["fetching"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.teams.fetching;
  }
);

/**
 * Returns the members of the selected team.
 */
export const selectedTeamMembersSelector: (
  state: RootState
) => SphereDashboardAPITypes.ITeamMember[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return Object.values(state.teams.selectedTeam.members);
  }
);

/**
 * Returns the filtered members of the selected team with search text.
 */
export const filteredSelectedTeamMembersSelector: (
  state: RootState
) => SphereDashboardAPITypes.ITeamMember[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const searchText = state.ui.search.debouncedSearchText.toLocaleLowerCase();
    const teamMembers = Object.values(state.teams.selectedTeam.members);

    // If the search text is less than {MIN_CHARACTERS_FOR_SEARCH} characters, return all team members
    // This is to avoid unnecessary filtering when the user is typing
    if (searchText.length < MIN_CHARACTERS_FOR_SEARCH) {
      return teamMembers;
    }

    const filteredTeamMembers = teamMembers.filter((teamMember) => {
      const firstName =
        teamMember.userResponse.firstName?.toLocaleLowerCase() ?? "";
      const lastName =
        teamMember.userResponse.lastName?.toLocaleLowerCase() ?? "";
      const email = teamMember.userResponse.email.toLocaleLowerCase() ?? "";

      return (
        firstName.includes(searchText) ||
        lastName.includes(searchText) ||
        email.includes(searchText)
      );
    });
    return filteredTeamMembers;
  }
);

/** Returns the selected team as member type */
export const selectedTeamAsMemberSelector: (
  state: RootState
) => SphereDashboardAPITypes.ICompanyMemberDetails | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.teams.selectedTeam.teamAsMember;
  }
);

/**
 * Returns the next team member ID if set.
 */
export const nextTeamMemberSelector: (state: RootState) => string | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.teams.nextTeamMember;
    }
  );

/**
 * Returns whether the details panel is opened.
 */
export const isDetailOpenedSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.teams.isDetailOpened;
    }
  );
