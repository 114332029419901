import { SphereTooltip } from "@components/common/sphere-tooltip";
import { RouteIdentifier } from "@hooks/use-route-match";
import { FormHelperText } from "@mui/material";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import { MIN_CHARACTERS_FOR_SEARCH } from "@utils/project-utils";
import { SearchbarEvents } from "@utils/track-event/track-event-list";
import { LogEventParams } from "@utils/track-event/use-track-event";

/** Configuration of the search bar */
interface SearchbarConfig {
  /** Whether searching is allowed for the current route/page */
  isSearchAllowed?: boolean;
  /** Placeholder text to use in the search bar input */
  placeholder?: string;
  /** Params to pass when logging the search event to amplitude */
  logEventParams?: LogEventParams;
  /** Function to validate the search value */
  validator?: (search: string) => boolean;
  /** Error message to display when the search value is not valid */
  errorMessage?: string;
}

/** Configuration of the search bar for each route/page */
export const SEARCHBAR_CONFIG: { [key in RouteIdentifier]: SearchbarConfig } = {
  projects: {
    isSearchAllowed: true,
    placeholder: "Search for project",
    logEventParams: {
      name: SearchbarEvents.searchForProject,
    },
    // For projects search at least 3 characters are required.
    // This is backend limitation so the frontend also needs to be consistent with this limitation.
    validator: (search) =>
      search.length === 0 || search.length >= MIN_CHARACTERS_FOR_SEARCH,
    errorMessage: "At least 3 characters required.",
  },
  members: {
    isSearchAllowed: true,
    placeholder: "Search for member",
    logEventParams: {
      name: SearchbarEvents.searchForWorkspaceMember,
    },
  },
  groups: {
    isSearchAllowed: true,
    placeholder: "Search for group",
    logEventParams: {
      name: SearchbarEvents.searchForGroup,
    },
  },
  workspaces: {
    isSearchAllowed: true,
    placeholder: "Search for workspace",
    logEventParams: {
      name: SearchbarEvents.searchForWorkspace,
    },
  },
  teams: {
    isSearchAllowed: true,
    placeholder: `Search for ${TEAM_DISPLAY_NAME}`,
    logEventParams: {
      name: SearchbarEvents.searchForTeam,
    },
  },
  teamMembers: {
    isSearchAllowed: true,
    placeholder: "Search for member",
    logEventParams: {
      name: SearchbarEvents.searchForTeamMember,
    },
  },
  projectDetails: {},
  account: {},
  unknown: {},
};

/** Default error message to display in the searchbar error helper */
const DEFAULT_ERROR_MESSAGE = "Invalid input.";

interface SearchbarErrorMsgProps {
  /** Error message to display */
  errorMessage?: string;
}

/** Returns the error message to display next to the search field when the search input is invalid */
export function SearchbarErrorMsg({
  errorMessage,
}: SearchbarErrorMsgProps): JSX.Element {
  return (
    <SphereTooltip title={errorMessage ?? DEFAULT_ERROR_MESSAGE}>
      <FormHelperText
        sx={{
          mt: 0,
          color: sphereColors.red500,
          ...withEllipsis,
        }}
      >
        {errorMessage ?? DEFAULT_ERROR_MESSAGE}
      </FormHelperText>
    </SphereTooltip>
  );
}
